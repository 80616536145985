import '../App.css';
import Container from "@mui/material/Container";
import {Grid} from "@mui/material";
import PlaygroundCard from "../components/PlaygroundCard";

function ExperiencePage() {
    return (
        <>
            <Container sx={{marginY: 5}}>
                <Grid container spacing={2}>
                    <PlaygroundCard name={"Connections"} description={"Inspired by the New York Times Connection puzzle"} image={"/images/connections-logo.png"}/>
                </Grid>
            </Container>
        </>
    );
}

export default ExperiencePage;
