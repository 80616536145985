import {Card, CardContent, Grid, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./connections.css";

function ConnectionsCategory({category}) {
    console.log(category);
    let style = {};
    if (category.level === 1) {
        style["backgroundColor"] = "var(--connections-color-1)"
    } else if (category.level === 2) {
        style["backgroundColor"] = "var(--connections-color-2)"
    } else if (category.level === 3) {
        style["backgroundColor"] = "var(--connections-color-3)"
    } else if (category.level === 4) {
        style["backgroundColor"] = "var(--connections-color-4)"
    }

    return (
        <Grid item xs={12} md={12} sx={{display: "flex", flexDirection: "column"}}>
            <Box>
                <Card elevation={3} sx={style}>

                    <CardContent>
                        <Typography sx={{ typography: { sm: 'h4', xs: 'body1' } }}  align="center" component={Stack} direction="column"
                                    justifyContent="center">
                            {category.connection}
                        </Typography>
                        <Typography sx={{ typography: { sm: 'body1', xs: 'body2' } }}  align="center">
                            {category.words.join(", ")}
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
        </Grid>
    );
}

export default ConnectionsCategory;