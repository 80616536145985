import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import './index.css';
import Root from './pages/Root';
import AboutPage from "./pages/AboutPage";
import ExperiencePage from "./pages/ExperiencePage";
import PlaygroundPage from "./pages/PlaygroundPage";
import reportWebVitals from './reportWebVitals';
import Home from './pages/Home'
import Connections from "./pages/Connections";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        errorElement: <Root/>,
        children: [
            {
                index: true,
                element: <Home/>
            },
            {
                path: "about/",
                element: <AboutPage/>
            },
            {
                path: "experience/",
                element: <ExperiencePage/>
            },
            {
                path: "playground/",
                element: <PlaygroundPage/>
            },
            {
                path: "playground/connections/",
                element: <Connections/>
            }
        ]
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
