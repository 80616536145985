import {Card, CardActionArea, CardContent, Grid, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./connections.css";

function ConnectionsSquare({word, handleClick, index, selection}) {
    let style = {height: "100%", display:"flex"};
    if (selection[index]["selected"] === true) {
        style["backgroundColor"] = "#848FA5";
    }

    return (
        <Grid item xs={3} md={3} sx={{display: "flex", flexDirection: "column"}}>
            <Box sx={{aspectRatio: 1}}>
                <Card elevation={3} sx={style}>
                    <CardActionArea onClick={() => {
                        handleClick(index)
                    }}>
                        <CardContent>
                            <Typography sx={{ typography: { sm: 'h4', xs: 'body1' } }}  align="center" component={Stack} direction="column"
                                        justifyContent="center">
                                {word}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Box>
        </Grid>
    );
}

export default ConnectionsSquare;