import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import {Link} from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import {useNavigate} from "react-router-dom";
import {Avatar} from "@mui/material";
import GitHubIcon from '@mui/icons-material/GitHub';
import {Link as MaterialLink} from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const pages = ['playground'];


function MenuBar() {
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar position="static" sx={{background: "var(--palette-color-1)"}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Avatar alt="Erik Ihren" src="/images/profile1.jpg"/>
                    <Button component={Link} to={"/"} sx={{my: 1, mx: 1, color: 'white', display: 'block'}}
                            disableRipple={true}>

                        Erik Ihren
                    </Button>
                    <Box sx={{mx: 20, flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        {pages.map((page) => (
                            <Button
                                key={page}
                                sx={{my: 1, mx: 1, color: 'white', display: 'block'}}
                                component={Link}
                                to={"/" + page}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>
                    <MaterialLink href="https://github.com/Siretu" target="_blank" sx={{mx: 2, display: 'flex', flexGrow: {xs:1, md:0}, flexDirection: 'row-reverse' }}>
                        <GitHubIcon/>
                    </MaterialLink>
                    <MaterialLink href="http://www.linkedin.com/in/erik-ihren" target="_blank" sx={{display: 'flex'}}>
                        <LinkedInIcon/>
                    </MaterialLink>
                    <Box sx={{display: {xs:'block', md:'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={(() => {
                                    navigate(page);
                                    handleCloseNavMenu();
                                })}>
                                    <Typography textAlign="center">{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default MenuBar;