import '../App.css';
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

function ExperiencePage() {
    return (
        <>
            <Container sx={{marginY: 5}} maxWidth="md">
                <Typography sx={{color:"var(--palette-color-1)"}} variant="h2">
                    Work Experience
                </Typography>
                <Typography sx={{color:"var(--palette-color-1)"}} variant="body1">
                    Apple and stuff
                </Typography>
            </Container>
        </>
    );
}

export default ExperiencePage;
