import '../App.css';
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

function ExperiencePage() {
    return (
        <>
            <Container sx={{marginY: 5}}>
                <Typography sx={{color:"var(--palette-color-1)"}} variant="body1">
                    Hello! I'm Erik, a Senior Software Engineer with a rich 8-year tenure at Apple,
                    specifically within the AI/ML organization. My journey has been marked by leading and contributing
                    to groundbreaking projects that enhance machine learning capabilities on over a billion devices
                    worldwide. As a tech lead, I've spearheaded the platform responsible for the seamless delivery of
                    improved machine learning models over-the-air, ensuring Apple devices stay at the forefront of
                    innovation.
                    <br/><br/>
                    With a deep-rooted passion for software engineering, I've some experience with virtually every area of
                    the field. My recent endeavors have focused on on-device software, blending backend and application
                    development to elevate the OS-level experiences on iPhones and Macs. Beyond my professional
                    experience, I dabble with game development using Unreal Engine, as well as some front-end development.
                    <br/><br/>
                    I'm committed to continuous learning and improvement, amd I'm always eager to understand new technologies
                    and tackle challenges in innovative ways. At the heart of my work ethic is
                    a dedication to mentoring junior engineers, fostering a collaborative environment where knowledge
                    sharing elevates the entire team's productivity. I am deeply invested in enhancing product quality
                    through rigorous automated testing and insightful analytics, setting a high bar for excellence in
                    every project I undertake.
                </Typography>
            </Container>
        </>
    );
}

export default ExperiencePage;
