import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {Grid} from "@mui/material";
import Link from "@mui/material/Link"

function Home() {
    return (
        <>
            <Container sx={{marginY: 5}}>
                <Grid container spacing={2}>
                    <Grid item xs={8} md={4}>
                        <img alt="Erik" src="/images/profile2.jpg" className="introImg"/>
                    </Grid>
                    <Grid item md>
                        <Typography sx={{color: "var(--palette-color-1)"}} variant="h2">
                            Hello! I'm Erik
                        </Typography>
                        <Typography sx={{color: "var(--palette-color-1)"}} variant="body1">
                            I'm a Senior Software Engineer with 8 years at Apple's AI/ML organization, where I've led
                            projects including delivering, evaluating and improving machine learning models across over
                            a billion devices.
                            <br/><br/>
                            My expertise lies in software engineering across various platforms, with a strong focus on
                            enhancing product quality and mentoring junior engineers to foster a collaborative and
                            productive team environment.
                            <br/><br/>
                            This site contains background on my previous experience & projects I've worked on. There are also some minor explorations of mine
                            in the playground. <Link href="/Erik-Resume-2023.pdf">You can also find my resume available for download here.</Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default Home;