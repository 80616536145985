import {Grid, Card, CardActionArea, CardMedia, CardContent} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from 'react-router-dom';


function PlaygroundCard({name, description, image}) {
    return (
        <Grid item xs={10} md={3}>
            <Card >
                <CardActionArea component={RouterLink} to={"/playground/"+ name} disableRipple={true}>
                    <CardMedia
                        component="img"
                        height="140"
                        image={image}
                        alt="green iguana"
                    />
                    <CardContent>
                        <Typography variant="subtitle1" component="h2">
                            {name}
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Typography variant="body2">
                                {description}
                            </Typography>
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
}


export default PlaygroundCard;