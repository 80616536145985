import Container from "@mui/material/Container";
import {Grid} from "@mui/material";
import connections from "../connections/connections-1.json"
import ConnectionsSquare from "../connections/ConnectionsSquare";
import {useState} from "react";
import Button from "@mui/material/Button";
import ConnectionsCategory from "../connections/ConnectionsCategory";

function Connections() {
    const initialSelection = connections.words.map(str => ({ word: str, selected: false }));
    const [selection, setSelection] = useState(initialSelection);
    const [words, setWords] = useState(connections.words);
    const [solvedCategories, setSolvedCategories] = useState([]);

    const handleSelect = (index) => {
        let selectedItems = 0;
        for (let key in selection) {
            if (selection[key]["selected"] === true) {
                selectedItems++;
            }
        }

        const nextSelection = selection.slice();
        if (nextSelection[index]["selected"] === true) {
            nextSelection[index]["selected"] = false;
            console.log("Unselected: " + nextSelection[index]["word"]);
        } else if (selectedItems < 4) {
            nextSelection[index]["selected"] = true;
            console.log("Selected: " + nextSelection[index]["word"]);
        }
        setSelection(nextSelection);
    }

    const solveCategory = (selectedWords, categoryIndex) => {
        if (selectedWords.length < 4) {
            console.log("Unexpected solveCategory call with less than 4 selected words: " + selectedWords);
            return;
        }
        var selectedWordIndex = 0
        var newWords = words.slice();
        // Go through all words in the row that we're now going to replace with the solved category and move them to other slots

        for (let index = 0; index < 4; ++index) {
            let word = newWords[index];
            let selectedWord = selectedWords[selectedWordIndex++];
            let selectedIndex = newWords.indexOf(selectedWord);
            newWords[index] = selectedWord;
            newWords[selectedIndex] = word;
            console.log("Swapped "+selectedWord+"("+selectedIndex+") to index "+index);
            console.log("Swapped "+word+"("+index+") to index "+selectedIndex);
        }
        newWords = newWords.slice(4); // Cut off the first 4 words
        setWords(newWords);
        let newSolvedCategories = solvedCategories.slice();
        newSolvedCategories.push(connections.solution[categoryIndex]);
        setSolvedCategories(newSolvedCategories);
        console.log("Solved categories are now: " + solvedCategories);
        console.log("Category index: " + categoryIndex);
        setSelection(newWords.map(str => ({ word: str, selected: false })));
    }

    const handleSubmit = () => {
        let selectedWords = [];
        for (let key in selection) {
            if (selection[key]["selected"] === true) {
                selectedWords.push(selection[key]["word"]);
            }
        }
        if (selectedWords.length < 4) {
            // Need to have selected 4 words to actually submit
            return;
        }
        console.log("Selected words: " + selectedWords);
        for (let categoryIndex in connections.solution) {
            let category = connections.solution[categoryIndex];
            console.log(category);
            var foundSolution = true;
            for (let index in selectedWords) {
                let word = selectedWords[index];
                if (!category.words.includes(word)) {
                    console.log("Category does not include word " + word);
                    foundSolution = false;
                    break;
                }
            }
            if (foundSolution) {
                solveCategory(selectedWords, categoryIndex);
                return;
            }
        }
        alert("Incorrect!");
    }

    return (
        <Container sx={{marginY: 5}} maxWidth="md">
            <Grid container spacing={2} justify="space-between" alignItems="stretch">
                {solvedCategories.map((category) => (
                    <ConnectionsCategory category={category}/>
                ))}
                {words.map((word, index) => (
                    <ConnectionsSquare key={index} index={index} word={word} handleClick={handleSelect} selection={selection}/>
                ))}
            </Grid>
            <Button variant="contained" sx={{marginY: 5}} onClick={handleSubmit}>
                Submit
            </Button>
        </Container>
    );
}

export default Connections;